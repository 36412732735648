import React from "react";
import logo from "../images/logo.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoLanguage } from "react-icons/io5";
import "../css/header.css"

export const Header = () => {
  return (
    <div className="z-20 sticky top-0 bg-white">
      {/* Navbar  */}

      <nav className="z-20 w-11/12 m-auto navbar">
        <div className="flex items-center justify-between mx-auto p-4">
          <div className="flex w-1/5">
            {/* <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button> */}
            <a
              href="#"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-12" />
            </a>
          </div>

          {/* NAVS */}
          <div
            className="flex items-center justify-end w-full"
            id="navbar-sticky"
          >
            <ul className="flex p-4  font-medium space-x-8 mr-3">
              <li>
                <a href="#" className=" text-[#868686]">
                  About us
                </a>
              </li>
              <li>
                <a href="#" className="flex text-[#868686]">
                  Services <MdKeyboardArrowDown className=" text-[25px]" />
                </a>
              </li>
              <li>
                <a href="#" className=" text-[#868686]">
                  Our Work
                </a>
              </li>
              <li>
                <a href="#" className=" text-[#868686]">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className=" text-[#868686]">
                  Career
                </a>
              </li>

              <li>
                <a href="#" className=" text-[#868686]">
                  Contact us
                </a>
              </li>
            </ul>

            <div className="space-x-3 ">
              <button
                type="button"
                className=" flex text-black bg-white ring-2 ring-[#111111] text-sm px-4 py-2 text-center relative after:absolute after:content-[''] after:bg-red after:w-[103%] after:h-[0px] after:bg-[#FFA32D] after:left-[-2px] after:bottom-[-2px] hover:after:h-[45px] transition1"
              >
                <IoLanguage className=" z-10 text-[25px] mr-1 border-2 p-0.5 rounded-md border-[#111111]" />{" "}
                <p className="z-10 text-[16px]">عربي to Eng</p>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
