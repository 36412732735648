import React from "react";
import "../css/header.css";
import img1 from "../images/Blog/img1.png";
import img2 from "../images/Blog/img2.png";
import img3 from "../images/Blog/img3.png";
import { ImArrowUpRight2 } from "react-icons/im";
import { Parallax } from "react-scroll-parallax";

export const Blog = () => {
  return (
    <div className="flex bg-[#fafeff] items-center mt-[180px] h-[100vh] overflow-y-scroll scrollHidden">
      <div className="w-11/12 m-auto px-10 py-16">
        <p
          data-aos="fade-down"
          className="text-center text-black text-[30px] mb-8 font-semibold font-['Space Grotesk']"
        >
          Our <span className="text-[#FFA32D]">Blogging</span> Odyssey
        </p>
        <div className="flex justify-center items-center">
          <Parallax scroll className="w-1/3 hover:bg-[#ddd] hoverImg p-5 border border-[#85c2c2]">
            <div
              data-aos="fade-up"
              // className="w-1/3 hover:bg-[#ddd] hoverImg p-5 border border-[#85c2c2]"
            >
              <div className="overflow-hidden w-full h-[319px] mb-3">
                <img src={img1} />
              </div>
              <p className="font-semibold">
                The Rise of Remote Work: Tools and Tips for Working from
                Anywhere
              </p>
              <ul className="list-disc flex mt-2 text-[#868686]">
                <li className="w-[38%] ml-5">2 min ago</li>
                <li className="w-[40%]">5 min to read</li>
              </ul>
              <button
                type="button"
                className="text-[#FFA32D] w-[52%] text-sm button-bg mt-7 p-3 text-center "
              >
                <div className="z-40 text-[16px] flex">
                  <p className="w-3/4 font-medium">Read Article</p>
                  <p className=" text-[20px] ml-3">
                    <ImArrowUpRight2 />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
          <Parallax speed={5} className="w-1/3 hover:bg-[#ddd] hoverImg p-5 border border-[#85c2c2]">
            <div data-aos="fade-up">
              <div className="overflow-hidden w-full h-[319px] mb-3">
                <img src={img2} />
              </div>
              <p className="font-semibold">
                The Rise of Remote Work: Tools and Tips for Working from
                Anywhere
              </p>
              <ul className="list-disc flex mt-2 text-[#868686]">
                <li className="w-[38%] ml-5">2 min ago</li>
                <li className="w-[40%]">5 min to read</li>
              </ul>
              <button
                type="button"
                className="text-[#FFA32D] w-[52%] text-sm button-bg mt-7 p-3 text-center "
              >
                <div className="z-40 text-[16px] flex">
                  <p className="w-3/4 font-medium">Read Article</p>
                  <p className=" text-[20px] ml-3">
                    <ImArrowUpRight2 />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
          <Parallax speed={10} className="w-1/3 hover:bg-[#ddd] hoverImg p-5 border border-[#85c2c2]">
            <div data-aos="fade-up">
              <div className="overflow-hidden w-full h-[319px] mb-3">
                <img src={img3} />
              </div>
              <p className="font-semibold">
                The Rise of Remote Work: Tools and Tips for Working from
                Anywhere
              </p>
              <ul className="list-disc flex mt-2 text-[#868686]">
                <li className="w-[38%] ml-5">2 min ago</li>
                <li className="w-[40%]">5 min to read</li>
              </ul>
              <button
                type="button"
                className="text-[#FFA32D] w-[52%] text-sm button-bg mt-7 p-3 text-center "
              >
                <div className="z-40 text-[16px] flex">
                  <p className="w-3/4 font-medium">Read Article</p>
                  <p className=" text-[20px] ml-3">
                    <ImArrowUpRight2 />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};
