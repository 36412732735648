import React from "react";
import "../css/header.css";
import { ImArrowUpRight2 } from "react-icons/im";
import lap1 from "../images/case/leptop1.png";
import lap2 from "../images/case/laptop2.png";
import vecB from "../images/case/vectorB.png";
import vecO from "../images/case/vectorO.png";
import { Parallax } from "react-scroll-parallax";

export const CaseStudy = () => {
  return (
    <div className="bg-[#161616]">
      <div className="w-11/12 m-auto px-10 py-12">
        <p
          data-aos="fade-down"
          className="text-center text-white text-[30px] font-semibold font-['Space Grotesk']"
        >
          Case <span className="text-[#FFA32D]">Study</span> Showcase
        </p>
        <div className="flex mt-8 justify-between items-center">
          <Parallax className="w-[50%]" speed={5}>
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="text-white"
            >
              <p className="text-[24px]  font-semibold">
                Gaffa Brand and website
              </p>
              <p className="text-[12px]  font-semibold">
                Tempstation Sailing is your ultimate destination for all things
                sailing. Whether you're a seasoned sailor or a beginner looking
                to dip your toes into the world of sailing, Tempstation Sailing
                has something for everyone.{" "}
              </p>
              <div className="flex gap-x-3">
                <button
                  type="button"
                  className="text-white text-sm mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Web Design </p>
                  </div>
                </button>{" "}
                <button
                  type="button"
                  className="text-white text-sm  mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Enterprise</p>
                  </div>
                </button>{" "}
                <button
                  type="button"
                  className="text-white text-sm  mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Development</p>
                  </div>
                </button>
              </div>
              <button
                type="button"
                className="text-black bg-white text-sm  mt-7 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
              >
                <div className="z-40 text-[16px] w-[185px] relative overflow-hidden	">
                  <p className="w-full lets font-medium">View Case Study</p>
                  <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                    <ImArrowUpRight2 />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
          <Parallax className="w-[40%]">
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              className="relative"
            >
              <div className="w-full overflow-hidden">
                <img
                  src={lap1}
                  className="z-10 relative transition1 hover:scale-105"
                />
              </div>
              <img src={vecO} className="absolute top-5 right-[-20px]" />
            </div>
          </Parallax>
        </div>
        <div className="flex mt-8 justify-between items-center">
          <Parallax className="w-[40%]" speed={-10} shouldAlwaysCompleteAnimation>
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="relative"
            >
              <div className="w-full overflow-hidden">
                <img
                  src={lap2}
                  className="z-10 relative transition1 hover:scale-105"
                />
              </div>
              <img src={vecB} className="absolute top-5 left-[-20px]" />
            </div>
          </Parallax>
          <Parallax className="w-[50%]" speed={5}>
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              className="text-white"
            >
              <p className="text-[24px]  font-semibold">
                Gaffa Brand and website
              </p>
              <p className="text-[12px]  font-semibold">
                Tempstation Sailing is your ultimate destination for all things
                sailing. Whether you're a seasoned sailor or a beginner looking
                to dip your toes into the world of sailing, Tempstation Sailing
                has something for everyone.{" "}
              </p>
              <div className="flex gap-x-3">
                <button
                  type="button"
                  className="text-white text-sm mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Web Design </p>
                  </div>
                </button>{" "}
                <button
                  type="button"
                  className="text-white text-sm  mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Enterprise</p>
                  </div>
                </button>{" "}
                <button
                  type="button"
                  className="text-white text-sm  mt-3 px-3 rgbaBlack py-2 text-center"
                >
                  <div className="z-40 text-[14px] relative overflow-hidden	">
                    <p className="w-full">Development</p>
                  </div>
                </button>
              </div>
              <button
                type="button"
                className="text-black bg-white text-sm  mt-7 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
              >
                <div className="z-40 text-[16px] w-[185px] relative overflow-hidden	">
                  <p className="w-full lets font-medium">View Case Study</p>
                  <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                    <ImArrowUpRight2 />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};
