import React, { Component, useEffect } from "react";
import "../css/header.css";
import dot from "../images/connect/Dot.png";
import dot4 from "../images/connect/Dot4.png";
import curly from "../images/connect/CurlyLine.png";
import vectorB from "../images/connect/VectorBlue.png";
import vectorO from "../images/connect/VectorOrange.png";
import { FaArrowRightLong } from "react-icons/fa6";

import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { Parallax } from "react-scroll-parallax";

const Connect = () => {
  let scrollContainer = document.querySelector(".gridC");

  const backButton = () => {
    let scrollContainer = document.querySelector(".gridC");
    scrollContainer.style.scrollBehavior = "smooth";
    scrollContainer.scrollTop -= 630;
  };

  const nextButton = () => {
    let scrollContainer = document.querySelector(".gridC");

    scrollContainer.style.scrollBehavior = "smooth";

    scrollContainer.scrollTop += 630;
  };

  return (
    <div className=" bg-[#fafeff] relative overflow-scroll scrollEvent">
      <div className=" w-11/12 m-auto py-14 flex justify-center items-center">
        {/* Coonect Discription */}
        <Parallax
          className="w-[45%]"
          style={{ transition: "0.5s ease-in-out" }}
        >
          <div>
            <div
              className="relative w-[50%] mb-0 m-5"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <p className="bg-white w-full shadow border py-2 pl-4 pr-12 font-semibold border-[#EAEAEA]">
                Our services
              </p>
              <img
                src={vectorO}
                className="absolute right-[-20px] top-[-20px]"
              />
            </div>
            <div
              className="flex relative bg-[#EBF3F3] ml-10 mb-5 p-4 w-[40%]"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img className="w-[10%] h-[15px] mr-3" src={dot4} />
              <img className="w-[5%] h-[5%] mr-0.5" src={dot} />
              <img className="w-[5%] h-[5%] mr-3" src={dot} />
              <img className="w-[65%] h-[10px]" src={curly} />
              <img
                src={vectorB}
                className="absolute left-[-40px] bottom-[-20px]"
              />
            </div>
            <div data-aos="fade-right" data-aos-duration="3000">
              <p className="text-[30px] pt-5 font-semibold">
                We love connecting with new people to talk about all things
                digital and tech!
              </p>
              <button
                type="button"
                className="text-white bg-black text-sm  mt-3 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
              >
                <div className="z-40 text-[16px] w-[138px] relative overflow-hidden	">
                  <p className="w-full lets font-medium">Let's Talk!</p>
                  <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                    <FaArrowRightLong />
                  </p>
                </div>
              </button>
            </div>
          </div>
        </Parallax>

        {/* Carousel */}
        <Parallax className="w-[55%] ml-5" speed={15}>
          <div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="gallery flex "
            >
              <div className="gridC w-[90%] h-[630px] overflow-y-scroll flex flex-col	gap-[20px]">
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA]  p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      className="imgShadow"
                    >
                      <g filter="url(#filter0_d_43_1314)">
                        <path d="M36.0666 11.3334H21.9333C17.3333 11.3334 15.6666 13 15.6666 17.6834V38.3167C15.6666 43 17.3333 44.6667 21.9333 44.6667H36.05C40.6666 44.6667 42.3333 43 42.3333 38.3167V17.6834C42.3333 13 40.6666 11.3334 36.0666 11.3334ZM29 40.1667C27.4 40.1667 26.0833 38.85 26.0833 37.25C26.0833 35.65 27.4 34.3334 29 34.3334C30.6 34.3334 31.9166 35.65 31.9166 37.25C31.9166 38.85 30.6 40.1667 29 40.1667ZM32.3333 18.4167H25.6666C24.9833 18.4167 24.4166 17.85 24.4166 17.1667C24.4166 16.4834 24.9833 15.9167 25.6666 15.9167H32.3333C33.0166 15.9167 33.5833 16.4834 33.5833 17.1667C33.5833 17.85 33.0166 18.4167 32.3333 18.4167Z" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_43_1314"
                          x="0.666626"
                          y="0.333374"
                          width="56.6666"
                          height="63.3334"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_1314"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_1314"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <p className="text-[20px] ml-5">Mobile & Web Development</p>
                  </div>
                  <p className="text-[13px] mt-3">
                    A description is a statement or account that gives the
                    characteristics of someone or something. It can also be a
                    piece of writing or speech that says what somebody/
                    something is like
                  </p>
                </div>
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA] p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      className=" imgShadow"
                    >
                      <g filter="url(#filter0_d_43_409)">
                        <path
                          d="M27.5207 11.8385C28.4383 11.3184 29.5617 11.3184 30.4793 11.8385L35.96 14.9449L41.3906 18.1381C42.2999 18.6728 42.8615 19.6456 42.8699 20.7003L42.92 27L42.8699 33.2997C42.8615 34.3544 42.2999 35.3272 41.3906 35.8619L35.96 39.0551L30.4793 42.1615C29.5617 42.6816 28.4383 42.6816 27.5207 42.1615L22.04 39.0551L16.6094 35.8619C15.7001 35.3272 15.1385 34.3544 15.1301 33.2997L15.08 27L15.1301 20.7003C15.1385 19.6456 15.7001 18.6728 16.6094 18.1381L22.04 14.9449L27.5207 11.8385Z"
                          // fill="red"
                          className="path"
                        />
                      </g>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M30.7778 28.3319C30.7778 31.0342 28.5911 33.2208 25.8889 33.2208C23.1867 33.2208 21 31.0342 21 28.3319V22.9986C21 22.2697 21.6044 21.6653 22.3333 21.6653C23.0622 21.6653 23.6667 22.2697 23.6667 22.9986V28.3319C23.6667 29.5586 24.6622 30.5542 25.8889 30.5542C27.1156 30.5542 28.1111 29.5586 28.1111 28.3319V22.9986C28.1111 22.2697 28.7156 21.6653 29.4444 21.6653C30.1733 21.6653 30.7778 22.2697 30.7778 22.9986V28.3319ZM33.4453 23.514L33.4443 22.9987C33.4443 22.252 34.0487 21.6653 34.7776 21.6653C35.5065 21.6653 36.1109 22.252 36.1109 22.9987L36.1109 31.2666L36.112 31.7819C36.112 32.5286 35.5075 33.1152 34.7786 33.1152C34.0498 33.1152 33.4453 32.5286 33.4453 31.7819V23.514Z"
                        className="pathH"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_d_43_409"
                          x="0.0799561"
                          y="0.448364"
                          width="57.8401"
                          height="61.1033"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_409"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_409"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <p className="text-[20px] ml-5">Interaction Design</p>
                  </div>
                  <p className="text-[13px] mt-3">
                    Emerging as the top performer in the MENA region by
                    pioneering products that redefine the market.
                  </p>
                </div>
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA] p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="58"
                      height="58"
                      viewBox="0 0 54 64"
                      className=" imgShadow"
                    >
                      <g filter="url(#filter0_d_43_466)">
                        <path d="M27 11.3334C22.6334 11.3334 19.0834 14.8834 19.0834 19.25C19.0834 23.5334 22.4334 27 26.8 27.15C26.9334 27.1334 27.0667 27.1334 27.1667 27.15C27.2 27.15 27.2167 27.15 27.25 27.15C27.2667 27.15 27.2667 27.15 27.2834 27.15C31.55 27 34.9 23.5334 34.9167 19.25C34.9167 14.8834 31.3667 11.3334 27 11.3334Z" />
                      </g>
                      <g filter="url(#filter1_d_43_466)">
                        <path d="M35.4667 31.5833C30.8167 28.4833 23.2334 28.4833 18.5501 31.5833C16.4334 33 15.2667 34.9166 15.2667 36.9666C15.2667 39.0166 16.4334 40.9166 18.5334 42.3166C20.8667 43.8833 23.9334 44.6666 27.0001 44.6666C30.0667 44.6666 33.1334 43.8833 35.4667 42.3166C37.5667 40.9 38.7334 39 38.7334 36.9333C38.7167 34.8833 37.5667 32.9833 35.4667 31.5833Z" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_43_466"
                          x="4.08337"
                          y="0.333374"
                          width="45.8334"
                          height="45.8167"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_466"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_466"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_43_466"
                          x="0.266724"
                          y="18.2583"
                          width="53.4667"
                          height="45.4083"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_466"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_466"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <p className="text-[20px] ml-5">Customer experience</p>
                  </div>
                  <p className="text-[13px] mt-3">
                    Enhancing interactions, exceeding expectations, fostering
                    loyalty, and driving business growth.
                  </p>
                </div>
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA] p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="66"
                      height="67"
                      viewBox="0 0 66 67"
                      className=" imgShadow"
                    >
                      <g filter="url(#filter0_d_43_998)">
                        <path d="M24.9166 14.8334V12.3334C24.9166 11.65 24.35 11.0834 23.6666 11.0834C22.9833 11.0834 22.4166 11.65 22.4166 12.3334V14.9334C22.8333 14.8834 23.2166 14.8334 23.6666 14.8334H24.9166Z" />
                      </g>
                      <g filter="url(#filter1_d_43_998)">
                        <path d="M38.25 14.9334V12.3334C38.25 11.65 37.6833 11.0834 37 11.0834C36.3167 11.0834 35.75 11.65 35.75 12.3334V14.8334H37C37.45 14.8334 37.8333 14.8834 38.25 14.9334Z" />
                      </g>
                      <g filter="url(#filter2_d_43_998)">
                        <path d="M48.882 38.0411C47.4438 38.0411 46.8558 37.024 47.5709 35.7765C47.9841 35.0534 47.7378 34.1317 47.0147 33.7185L45.6401 32.9319C45.0123 32.5584 44.2019 32.7809 43.8284 33.4086L43.741 33.5596C43.0259 34.8071 41.8499 34.8071 41.1268 33.5596L41.0394 33.4086C40.6819 32.7809 39.8714 32.5584 39.2437 32.9319L37.869 33.7185C37.146 34.1317 36.8996 35.0614 37.3128 35.7844C38.0359 37.024 37.4479 38.0411 36.0097 38.0411C35.1833 38.0411 34.5 38.7165 34.5 39.5508V40.9492C34.5 41.7756 35.1754 42.4589 36.0097 42.4589C37.4479 42.4589 38.0359 43.476 37.3128 44.7235C36.8996 45.4466 37.146 46.3683 37.869 46.7815L39.2437 47.5681C39.8714 47.9416 40.6819 47.7191 41.0553 47.0914L41.1427 46.9404C41.8578 45.6929 43.0338 45.6929 43.7569 46.9404L43.8443 47.0914C44.2178 47.7191 45.0282 47.9416 45.656 47.5681L47.0306 46.7815C47.7537 46.3683 48 45.4386 47.5868 44.7235C46.8637 43.476 47.4517 42.4589 48.8899 42.4589C49.7163 42.4589 50.3996 41.7835 50.3996 40.9492V39.5508C50.3917 38.7244 49.7163 38.0411 48.882 38.0411ZM42.4458 42.8324C41.0235 42.8324 39.8634 41.6723 39.8634 40.25C39.8634 38.8277 41.0235 37.6676 42.4458 37.6676C43.8681 37.6676 45.0282 38.8277 45.0282 40.25C45.0282 41.6723 43.8681 42.8324 42.4458 42.8324Z" />
                      </g>
                      <g filter="url(#filter3_d_43_998)">
                        <path d="M38.25 14.9334V17.3334C38.25 18.0167 37.6834 18.5834 37 18.5834C36.3167 18.5834 35.75 18.0167 35.75 17.3334V14.8334H24.9167V17.3334C24.9167 18.0167 24.35 18.5834 23.6667 18.5834C22.9834 18.5834 22.4167 18.0167 22.4167 17.3334V14.9334C17.5 15.3834 15.3334 18.55 15.3334 23.1667V37.3334C15.3334 42.3334 17.8334 45.6667 23.6667 45.6667H30.55C31.8167 45.6667 32.6667 44.25 32.35 43.0167C32.1167 42.1167 32 41.1834 32 40.25C32 36.7834 33.55 33.5834 36.2334 31.4667C38.1834 29.8834 40.6834 29 43.25 29H43.3167C44.3667 29 45.3334 28.2334 45.3334 27.1834V23.1667C45.3334 18.55 43.1667 15.3834 38.25 14.9334ZM27 36.9167H23.6667C22.9834 36.9167 22.4167 36.35 22.4167 35.6667C22.4167 34.9834 22.9834 34.4167 23.6667 34.4167H27C27.6834 34.4167 28.25 34.9834 28.25 35.6667C28.25 36.35 27.6834 36.9167 27 36.9167ZM32 28.5834H23.6667C22.9834 28.5834 22.4167 28.0167 22.4167 27.3334C22.4167 26.65 22.9834 26.0834 23.6667 26.0834H32C32.6834 26.0834 33.25 26.65 33.25 27.3334C33.25 28.0167 32.6834 28.5834 32 28.5834Z" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_43_998"
                          x="7.41663"
                          y="0.083374"
                          width="32.5"
                          height="33.85"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_998"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_998"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_43_998"
                          x="20.75"
                          y="0.083374"
                          width="32.5"
                          height="33.85"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_998"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_998"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter2_d_43_998"
                          x="19.5"
                          y="21.75"
                          width="45.8997"
                          height="45"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_998"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_998"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter3_d_43_998"
                          x="0.333374"
                          y="3.83337"
                          width="60"
                          height="60.8334"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_998"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_998"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <p className="text-[20px] ml-5">Managed services</p>
                  </div>
                  <p className="text-[13px] mt-3">
                    Seamless IT operations with our Managed Services. Proactive
                    support, security, and efficiency solutions.
                  </p>
                </div>
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA] p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="58"
                      height="58"
                      viewBox="-13 -15 64 58"
                      className=" imgShadow"
                    >
                      <path d="M14.8744 2.37537H10.6219C10.0738 2.37537 9.62939 2.81975 9.62939 3.36789V7.62032C9.62939 8.16846 10.0738 8.61285 10.6219 8.61285H14.8744C15.4225 8.61285 15.8669 8.16846 15.8669 7.62032V3.36789C15.8669 2.81975 15.4225 2.37537 14.8744 2.37537Z" />
                      <path d="M14.8038 16.125V11.8725C14.8038 11.3244 14.3594 10.88 13.8112 10.88H9.55881C9.01067 10.88 8.56628 11.3244 8.56628 11.8725V16.125C8.56628 16.6731 9.01067 17.1175 9.55881 17.1175H13.8112C14.3594 17.1175 14.8038 16.6731 14.8038 16.125Z" />
                      <path d="M14.8744 19.3847H10.6219C10.0738 19.3847 9.62939 19.8291 9.62939 20.3772V24.6297C9.62939 25.1778 10.0738 25.6222 10.6219 25.6222H14.8744C15.4225 25.6222 15.8669 25.1778 15.8669 24.6297V20.3772C15.8669 19.8291 15.4225 19.3847 14.8744 19.3847Z" />
                      <path d="M5.30648 15.1324H1.05405C0.505909 15.1324 0.0615234 15.5768 0.0615234 16.1249V20.3773C0.0615234 20.9255 0.505909 21.3699 1.05405 21.3699H5.30648C5.85462 21.3699 6.299 20.9255 6.299 20.3773V16.1249C6.299 15.5768 5.85462 15.1324 5.30648 15.1324Z" />
                      <path d="M5.30648 6.62769H1.05405C0.505909 6.62769 0.0615234 7.07207 0.0615234 7.62021V11.8726C0.0615234 12.4208 0.505909 12.8652 1.05405 12.8652H5.30648C5.85462 12.8652 6.299 12.4208 6.299 11.8726V7.62021C6.299 7.07207 5.85462 6.62769 5.30648 6.62769Z" />
                      <path d="M33.5933 17.5522L31.1619 15.4669C31.2971 14.4979 31.2971 13.4988 31.1619 12.5299L33.5933 10.4446C33.9558 10.1336 34.0455 9.60855 33.8067 9.19493L31.4147 5.05207C31.1759 4.63846 30.6763 4.45365 30.2257 4.61205L27.202 5.67591C26.4243 5.06663 25.5731 4.57421 24.6606 4.20558L24.0704 1.05823C23.9823 0.588828 23.5724 0.248657 23.0948 0.248657H19.1268C18.5787 0.248657 18.1343 0.693043 18.1343 1.24118V8.921C18.1343 9.23642 18.2841 9.53299 18.5381 9.72004C18.792 9.90717 19.1197 9.96242 19.4209 9.86892C22.1352 8.98412 25.0719 11.1365 25.0258 13.9984C25.072 16.8597 22.1349 19.0133 19.4209 18.1278C19.1197 18.0343 18.7921 18.0897 18.5381 18.2767C18.2842 18.4638 18.1343 18.7604 18.1343 19.0758V26.7556C18.1343 27.3038 18.5787 27.7482 19.1268 27.7482H23.0948C23.5724 27.7482 23.9824 27.408 24.0704 26.9386L24.6606 23.7912C25.5731 23.4226 26.4243 22.9301 27.202 22.3209L30.2257 23.3848C30.6763 23.5432 31.1758 23.3584 31.4147 22.9448L33.8067 18.8019C34.0456 18.3882 33.9559 17.8632 33.5933 17.5522Z" />
                    </svg>
                    <p className="text-[20px] ml-5">
                      Digital transformation consulting
                    </p>
                  </div>
                  <p className="text-[13px] mt-3">
                    Unlock your organization's full potential with our expert
                    digital transformation consulting services.
                  </p>
                </div>
                <div className="afterEffect w-[80%] min-h-[190px] max-h-[190px] border border-[#EAEAEA] p-4">
                  <div className="flex items-center text-[#5ABCC2] font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      className=" imgShadow"
                    >
                      <g filter="url(#filter0_d_43_1410)">
                        <path d="M44.0667 17.298H43.4L37.7667 11.6647C37.3167 11.2147 36.5834 11.2147 36.1167 11.6647C35.6667 12.1147 35.6667 12.848 36.1167 13.3147L40.1 17.298H23.9L27.8834 13.3147C28.3334 12.8647 28.3334 12.1314 27.8834 11.6647C27.4334 11.2147 26.7 11.2147 26.2334 11.6647L20.6167 17.298H19.95C18.45 17.298 15.3334 17.298 15.3334 21.5647C15.3334 23.1814 15.6667 24.248 16.3667 24.948C16.7667 25.3647 17.25 25.5814 17.7667 25.698C18.25 25.8147 18.7667 25.8314 19.2667 25.8314H44.7334C45.25 25.8314 45.7334 25.798 46.2 25.698C47.6 25.3647 48.6667 24.3647 48.6667 21.5647C48.6667 17.298 45.55 17.298 44.0667 17.298Z" />
                      </g>
                      <g filter="url(#filter1_d_43_1410)">
                        <path d="M43.75 27.998H20.1166C19.0833 27.998 18.3 28.9147 18.4666 29.9314L19.8666 38.498C20.3333 41.3647 21.5833 44.6647 27.1333 44.6647H36.4833C42.1 44.6647 43.1 41.848 43.7 38.698L45.3833 29.9814C45.5833 28.948 44.8 27.998 43.75 27.998ZM32 40.498C28.1 40.498 24.9166 37.3147 24.9166 33.4147C24.9166 32.7314 25.4833 32.1647 26.1666 32.1647C26.85 32.1647 27.4166 32.7314 27.4166 33.4147C27.4166 35.948 29.4666 37.998 32 37.998C34.5333 37.998 36.5833 35.948 36.5833 33.4147C36.5833 32.7314 37.15 32.1647 37.8333 32.1647C38.5166 32.1647 39.0833 32.7314 39.0833 33.4147C39.0833 37.3147 35.9 40.498 32 40.498Z" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_43_1410"
                          x="0.333374"
                          y="0.327209"
                          width="63.3334"
                          height="44.5042"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_1410"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_1410"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_43_1410"
                          x="3.44421"
                          y="16.998"
                          width="56.9705"
                          height="46.6667"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="7.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_43_1410"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_43_1410"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <p className="text-[20px] ml-5">E-Commerce</p>
                  </div>
                  <p className="text-[13px] mt-3">
                    Empowering online businesses with seamless e-commerce
                    solutions for scalable growth and enhanced customer
                    experiences.
                  </p>
                </div>
              </div>
              <div className="w-[10%] flex flex-col gap-[30px] justify-center">
                <button
                  onClick={() => backButton()}
                  className="h-[54px] border border-black rounded-full"
                >
                  <FaArrowUp className=" p-1 m-auto text-[30px]" />
                </button>
                <button
                  onClick={() => nextButton()}
                  className=" border h-[54px]  border-black rounded-full"
                >
                  <FaArrowDown className=" p-1 m-auto text-[30px]" />
                </button>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

    </div>
  );
};

export default Connect;
