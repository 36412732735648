import React from "react";
import "../css/header.css";
import logo from "../images/logoWhite.png";
import instaWhite from "../images/instaWhite.png";
import ract1 from "../images/ract1.png";
import ract2 from "../images/ract2.png";
// import instaColor from "../images/instaColor.png";
import { FaFacebookSquare } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { IoLanguage } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaArrowRightLong, FaLocationDot } from "react-icons/fa6";

export const Footer = () => {
  return (
    <div className="bg-[#101010] relative height-[90vh]">
      <div className="w-11/12 m-auto pt-24 mt-24">
        <div className="p-14">
          <div className="flex borderBo">
            <div data-aos="fade-up" className="w-[60%]">
              <img src={logo} />
            </div>
            <div
              data-aos="fade-up"
              className="w-[40%] flex items-center justify-end"
            >
              <button
                type="button"
                className=" flex text-white bg-transparent border border-[#414141] text-sm px-4 py-2 text-center hover:bg-white hover:text-black transition1"
              >
                <IoLanguage className=" z-10 text-[25px] mr-1 border-2 p-0.5 rounded-md border-[#111111]" />{" "}
                <p className="z-10 text-[16px]">عربي to Eng</p>
              </button>
              <img
                src={instaWhite}
                className="text-[25px] text-white insta transition1 ml-3"
              />
              <FaFacebookSquare className="text-[25px] text-white transition1 ml-3 hover:text-[#477BFF]" />
              <BsTwitterX className="text-[25px] text-white ml-3" />
              <FaLinkedin className="text-[25px] text-white transition1 ml-3 hover:text-[#2868B2]" />
            </div>
          </div>
          <div className="flex mt-10">
            <div data-aos="fade-up" className="w-[40%]">
              <p className="font-semibold text-[#FFA32D] mb-3">Information</p>
              <ul className="text-[#868686] gap-y-2 spanHo">
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> About us
                  </a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> Serivices
                  </a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> Our work
                  </a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> Blog
                  </a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> Career
                  </a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a href="">
                    <span>//</span> Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" className="w-[30%]">
              <p className="font-semibold text-[#FFA32D] mb-3">Helpful Link</p>
              <ul className="text-[#868686] gap-y-2 spanHo">
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a><span>//</span> Services</a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a><span>//</span> Supports</a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a><span>//</span> Terms & Condition</a>
                </li>
                <li className="mb-1 hover:text-[white] transition1 hover:translate-x-5">
                  <a><span>//</span> Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" className="w-[30%]">
              <p className="font-semibold text-[#FFA32D] mb-3">Contact Us</p>
              <ul className="text-white gap-y-2">
                <li className="mb-1 flex hover:text-[#ffa32d] transition1">
                  <FaPhoneAlt className="bg-[#282828] p-1.5 mr-2 text-[30px] rounded-full" />
                  <p>865-448-5900</p>
                </li>
                <li className="mb-1 flex hover:text-[#ffa32d] transition1">
                  <MdMarkEmailUnread className="bg-[#282828] p-1.5 mr-2 text-[30px] rounded-full" />
                  <p>tarmeezinfo@gmail.com</p>
                </li>
                <li className="mb-1 flex hover:text-[#ffa32d] transition1">
                  <FaLocationDot className="bg-[#282828] p-1.5 mr-2 text-[30px] rounded-full" />
                  <p>441 N Hall Rd. Alcoa, TN 37701</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className=" border-t border-[#3E3E3E] p-4">
        <div className="w-11/12 m-auto">
          <p className="text-center text-[#868686]">2024 @ Tarmeez Co</p>
        </div>
      </div>
      <div className="absolute overflow-hidden w-11/12 left-[4%] bgImage top-[-120px] flex items-center justify-between px-28 py-16 top-0 bg-[#6DD1D7]">
        <div className="w-1/2 relative">
          <p className="text-5xl font-semibold mb-3">Let’s Talk...</p>
          <p>
            Enables users to initiate conversations with our team in real-time.
          </p>
          <img
            src={ract2}
            className="absolute bottom-[-59%] animate left-[-26%] w-[100px]"
          />
        </div>
        <div className="w-1/2  relative">
          <div className="justify-end flex">
            <button
              type="button"
              className="text-white bg-black text-sm  mt-3 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
            >
              <div className="z-40 text-[16px] w-[138px] relative overflow-hidden">
                <p className="w-full lets font-medium">Let's Talk!</p>
                <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                  <FaArrowRightLong />
                </p>
              </div>
            </button>
          </div>
          <img
            src={ract1}
            className="absolute top-[-161%] animate right-[-13%] w-[100px]"
          />
        </div>
      </div>
    </div>
  );
};
