import React from "react";
import img1 from "../images/happy/img1c.png";
import img2 from "../images/happy/img2c.png";
import img3 from "../images/happy/img3c.png";
import img4 from "../images/happy/img4c.png";
import img5 from "../images/happy/img5c.png";
import img6 from "../images/happy/img6c.png";
import stroke from "../images/StrokeBox.png"
import "../css/header.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { Parallax } from "react-scroll-parallax";

export const Happy = () => {
  return (
    <div className="h-[100vh] bg-[#fafeff] flex items-center overflow-hidden">
      <div className="w-11/12 relative z-10 m-auto flex p-12 stbox">
        <div data-aos="fade-right"  data-aos-duration="3000" className="w-1/2">
          <p className="font-semibold text-[28px]">Our</p>
          <p className="font-bold text-[48px]">
            Happy
            <br /> Customer
          </p>
          <button
            type="button"
            className="text-white bg-black text-sm  mt-3 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
          >
            <div className="z-40 text-[16px] w-[138px] relative overflow-hidden	">
              <p className="w-full lets font-medium">View More</p>
              <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                <FaArrowRightLong />
              </p>
            </div>
          </button>
        </div>
        <Parallax className="w-1/2 py-5 relative" speed={5}>
          <div
            data-aos="fade-left"
            data-aos-duration="3000"
            className="flex flex-wrap justify-center items-center overflow-visible"
          >
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img1}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img2}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img3}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img4}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img5}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
            <div className="w-1/3 flex relative after:absolute after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#FFA32D] after:top-[-8px] after:right-[-20px] hover:after:right-[-8px] before:absolute before:w-[20px] before:h-[20px] before:rounded-full before:bg-[#5ABBC0] before:bottom-[-8px] before:left-[-20px] hover:before:left-[-8px] transition1 overflow-hidden items-center border border-[#ddd] h-[103px]">
              <img
                src={img6}
                className="grayscale m-auto hover:grayscale-0 p-5 transition1"
              />
            </div>
          </div>  
        </Parallax>
      </div>
          <img src={stroke} className="absolute w-[92%] h-[342px] left-[48px] right-[48px]" />
    </div>
  );
};
