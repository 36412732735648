import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </ParallaxProvider>
    </BrowserRouter>
  );
}

export default App;
