import React, { useEffect } from "react";
import "../css/header.css";
import { FaArrowRightLong } from "react-icons/fa6";
import Aos from "aos";
import "aos/dist/aos.css";
import { Parallax } from "react-scroll-parallax";

const Hero = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Parallax speed={-10}>
      <div className="poly" data-aos="fade-down" data-aos-duration="2000">
        <div className="polyPath p1">
          <Parallax
            speed={-14}
            className="absolute w-[40%] left-[5%] bottom-[5%]"
          >
            <div data-aos="fade-right" data-aos-duration="2000">
              <p className="text-[50px]">
                <span className="font-semibold">
                  Crafting intelligent solutions
                </span>{" "}
                from your <span className="font-semibold">concepts.</span>
              </p>
              <button
                type="button"
                className="text-white bg-black text-sm  mt-3 py-3 text-center relative after:absolute after:content-[''] after:bg-red after:w-full after:h-[0px] after:bg-[#FFA32D] after:left-0 after:bottom-0 hover:after:h-full transition1"
              >
                <div className="z-40 text-[16px] w-[138px] relative overflow-hidden	">
                  <p className="w-full lets font-medium">Let's Talk!</p>
                  <p className="letsArrow text-[20px] absolute bottom-0 right-[-20px]">
                    <FaArrowRightLong />
                  </p>
                </div>
              </button>
            </div>
          </Parallax>
          {/* </Parallax> */}
        </div>
        <div className="polyPath p2 relative after:absolute after:content-[''] after:w-[300px] after:h-[2px] after:bg-white after:bottom-28 after:right-28 after:rotate-[30deg] before:absolute before:content-[''] before:w-[250px] before:h-[2px] before:bg-white before:bottom-52 before:right-20 before:rotate-[50deg]"></div>
        <div className="polyPath p3"></div>
      </div>
    </Parallax>
  );
};

export default Hero;
