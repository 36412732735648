import React from "react";
import { Header } from "../components/Header";
import Hero from "../components/Hero";
import Connect from "../components/Connect";
import { CaseStudy } from "../components/CaseStudy";
import { Blog } from "../components/Blog";
import { Footer } from "../components/Footer";
import Fullpage, {
  FullPageSections,
  FullpageSection,
} from "@ap.cx/react-fullpage";
import { Happy } from "../components/Happy";
import { Parallax } from "react-scroll-parallax";

const Home = () => {
  return (
    <div>
      <Header />
      <Fullpage>
        <FullPageSections>
          <FullpageSection>
            <Hero />
          </FullpageSection>
          <FullpageSection>
            <Connect />
          </FullpageSection>
          <FullpageSection>
            <Happy />
          </FullpageSection>
          <FullpageSection style={{ height: "70vh" }}>
            <CaseStudy />
          </FullpageSection>
          <FullpageSection style={{ height: "70vh" }}></FullpageSection>
          <FullpageSection style={{ height: "150vh" }}>
            <Blog />
          </FullpageSection>
          {/* <Parallax speed={-10}> */}
            {/* <FullPageSections style={{height : "50vh"}}></FullPageSections> */}
          {/* </Parallax> */}
          <FullpageSection>
            <Footer />
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    </div>
  );
};

export default Home;
